<template>
  <div class="splash-screen">
    <span class="logo-img">
      <img src="../assets/images/partner_logo.svg" alt="" />
    </span>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
// import ApiRequest from "@/services/ApiRequest";
// import { BASE_API } from "@/utils/constants";
// import { GET } from "@/utils/HttpStatusCodeConst";
// import { redirectPartnerByStatus } from "@/utils/Helper";
// import Swal from "sweetalert2";
export default {
  name: "Splash",
  components: {},
  data() {
    return {};
  },
  mounted() {
    // this.getPartnerTasks();
    // this.getUserInfo();
    if(this.prevRoute.path !== "/dashboard"){
      this.$router.push("/dashboard");
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
  methods: {
    ...mapMutations({
      // setPartnerTasks: "setPartnerTasks",
      // setPartnerStatus: "setPartnerStatus",
      // setUserInfo: "setUserInfo",
    }),
    // getUserInfo() {
    //   ApiRequest(BASE_API, `/get/partner91`, GET)
    //     .then((response) => {
    //       this.setUserInfo(response);
    //     })
    //     .catch(() => {
    //       Swal.fire({ text: this.$t("_pleaseTryAgain"), icon: "error" });
    //     });
    // },
    // getPartnerTasks() {
    //   this.isLoading = true;
    //   ApiRequest(BASE_API, "/partner91/dashboard", GET)
    //     .then((response) => {
    //       const { payment, status } = response;
    //       this.setPartnerTasks(response.data);
    //       this.setPartnerStatus({ payment, status });
    //       this.isLoading = false;
    //       //redirect on partner validation
    //       if(this.prevRoute.path !== "/dashboard")
    //       {
    //         this.$router.push("/dashboard");
    //       }
    //     })
    //     .catch((error) => {
    //       console.log("error:", error);
    //       this.isLoading = false;
    //     });
    // },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/splash.scss";
</style>
